<style>
	.footer {
		padding: 0;
		height: 100% !important;
		background: #001529 no-repeat bottom center;
		background-size: 100% auto;
	}
	.wrap {
		margin: 0 auto;
		width: 1200px;
		min-width: 1200px;
	}
	.login-bg {
		/* background: #022346 url(../../assets/login.png) no-repeat center center; */
		/* background-size: 100% 100%; */
		width: 100%;
		height: 100%;
	}

	.login-bg .el-input__inner {
		border-width: 0 0 1px 0;
		border-radius: 0;
		border-color: #1a91ff !important;
		background: transparent;
	}
	.login-bg .el-input__prefix {
		left: 0;
	}
	.login-bg .el-input__prefix i {
		font-size: 22px;
		color: #1a91ff;
	}
	.login-card {
		background: url(../../assets/login_card.png) no-repeat center center;
		background-size: 100% 100%;
		padding: 20px 40px;
	}
	.login-card .el-input__inner {
		color: #1a91ff;
	}
	.login-card input::-webkit-input-placeholder {
		color: #1a91ff;
	}
</style>
<template>
	<div style="background:linear-gradient(to top left,#0a237c,#2a62a9);width:100%;height:100%">
		<video-bg id="bg" :sources="['http://zhny.rayoai.com/video/bg1.mp4']" img="../../assets/login.png">
			<el-container class="login-bg">
				<el-header height="64px" style="padding:0;background:#001529aa;">
					<div class="wrap" style="padding:16px 0">
						<!-- <img src="../../assets/logo.png" style="width: 42px;margin: 0px 16px 0px 0;position: relative;top:10px" /> -->
						<span style="color: #fff;margin: 16px 0;font-size: 20px;letter-spacing: 1px;color:#fff;">综合能源管控云平台</span>
					</div>
				</el-header>
				<!-- <h5 style="margin-top:110px;color:#fff;padding:40px ;font-weight:300;line-height: 28px;font-size: 14px;background:linear-gradient(45deg,transparent 34px,rgba(0,0,0,.6) 0) top left;">
							<span style="font-size:24px;font-weight:400;">基于物联网云平台的综合能源管控系统</span><br /><br />
							通过485等通信协议采集智能电表参数，对数据进行协议分析，组帧，设计电能采集终端及数据集中器，通过无线方式将采集到的各类参数上传给服务器；设计服务器软件，能够实现数据的协议接收、存储、展示等云服务。
						</h5> -->
				<el-main style="padding:0px;">
					<el-card shadow="always" :body-style="{overflow: 'visible'}" style="background: rgba(0,0,0,0.9);border:0;overflow: visible;width:320px;margin:8% 150px 0 auto;">
						<!-- <img src="../../assets/company.png" style="height: 70px;margin-left: -10px;" /> -->
						<h3 style="line-height:44px;font-weight:500;margin-top: 0px;text-align:center;color:#1a91ff">综合能源管控云平台</h3>
						<el-alert v-show="loginError" type="error" :title="loginErrorMessage" :closable="false" show-icon style="margin: 0 0 15px 0;" />
						<el-form class="login-card" ref="login_ref" :model="login_data" :rules="login_rules" :show-message="false" size="medium">
							<el-form-item prop="name">
								<el-input type="text" v-model="login_data.name" placeholder="请输入手机号" prefix-icon="iconfont el-icon-shoujihao" />
							</el-form-item>
							<el-form-item prop="pwd">
								<el-input type="password" v-model="login_data.pwd" placeholder="请输入密码" prefix-icon="iconfont el-icon-mima" />
							</el-form-item>
							<!-- <el-link type="primary" :underline="false" style="float:right">忘记登录密码？</el-link> -->

						</el-form>
						<el-button type="primary" round style="margin-top:30px;width:100%;background:#1a91ff" @click="login('login_ref')">登 录</el-button>
					</el-card>
				</el-main>
				<div style="background:#001529aa;">
					<!-- <h4 style="color:rgba(255,255,255,1);font-weight:400;text-align:center;margin-top:30px;margin-bottom:0"><span style="margin-right:20px">关于我们</span><span style="margin-right:20px">法律声明</span><span style="margin-right:20px">服务条款</span><span style="margin-right:20px">联系方式</span></h4> -->
					<!-- <h5 style="color:rgba(255,255,255,.7);font-weight:300;text-align:center;margin-top:20px;margin-bottom:0"> 邮编：454002 </h5> -->
					<h5 style="color:rgba(255,255,255,.7);font-weight:300;text-align:center;margin-top:10px">建议使用Google Chrome浏览器、Firefox浏览器、IE10及以上版本浏览器、双核浏览器的极速模式</h5>
				</div>

				<!-- If you want to add content here, a slot is waiting! -->

			</el-container>
		</video-bg>
	</div>
</template>
<script>
	import request from "../../plugins/axios.js";
	import VideoBg from "vue-videobg";
	export default {
		data() {
			return {
				//登录报错信息
				loginError: false,
				loginErrorMessage: "",
				//登录表单
				login_data: {
					name: "",
					pwd: "",
				},
				//登录表单验证规则
				login_rules: {
					name: [{ required: true, message: "请输入登录账号" }],
					pwd: [{ required: true, message: "请输入登录密码" }],
				},
			};
		},
		components: { VideoBg },
		mounted() {
			//设置背景动画播放速度
			let obj = document.getElementById("bg").childNodes;
			obj[0].playbackRate = 0.5;
		},
		methods: {
			go_login() {
				this.$router.push("login");
			},
			login(form_ref) {
				this.$refs[form_ref].validate((vaild, fields) => {
					if (vaild) {
						//提交表单
						this.loginError = false;
						this.loginErrorMessage = "";
						request({
							url: "/admin/Auth/login",
							data: {
								mobile: this.login_data.name,
								pwd: this.login_data.pwd,
							},
						}).then(
							(ret) => {
								if (ret.code == 1) {
									window.localStorage.setItem("admin_id", ret.data.id);
									window.localStorage.setItem("token", ret.data.token);
									window.localStorage.setItem("role", ret.data.role);
									window.localStorage.setItem("nickname", ret.data.nickname);
									window.localStorage.setItem("head", ret.data.head);
									// window.localStorage.setItem("uid", ret.data.uid);
									if (
										ret.data.role == "超级管理员" ||
										ret.data.role == "普通管理员"
									) {
										this.$router.push("/admin/index");
									} else {
										this.$router.push("/company/stat");
										window.localStorage.setItem(
											"company_id",
											ret.data.company_id
										);
										window.localStorage.setItem(
											"company_name",
											ret.data.company_name
										);
									}
								} else {
									this.loginError = true;
									this.loginErrorMessage = ret.msg;
								}
							},
							(err) => {
								this.loginErrorMessage = err;
							}
						);
					} else {
						this.loginError = true;
						const fields_name = Object.keys(fields);
						this.loginErrorMessage = fields[fields_name[0]][0].message;
						return false;
					}
				});
			},
		},
	};
</script>